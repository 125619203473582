<template>
  <div>
    <b-overlay
        :show=show
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
        style="max-width: 100%"
    >
      <h1 class="pt-3 pb-4">{{ $t('signup.title') }}</h1>
      <div class="ng-star-inserted">
        <div class="ng-untouched ng-pristine ng-invalid">
          <div class="form-group">
            <select class="ng-untouched ng-pristine ng-valid"
                    v-model="$i18n.locale" @change="onLocaleChange($event)">
              <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`"
                      :value="locale">
                {{ locale === 'ko' ? '한국어' : 'English' }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <select class="ng-untouched ng-pristine ng-valid" v-model="country">
              <option :value=item.code class="ng-star-inserted" v-for="(item,index) in country_list"
                      :key="'l_'+index">{{ item.text }}
              </option>
            </select>
          </div>

          <div class="form-group">
            <input type="email" class="ng-untouched ng-pristine ng-invalid" v-model="id"
                   :placeholder="this.$i18n.t('signup.id')">
          </div>
          <div class="form-group">
            <input type="text" class="ng-untouched ng-pristine ng-invalid" v-model="email"
                   :placeholder="this.$i18n.t('signup.email')">
          </div>
          <div class="form-group">
            <input type="text" class="ng-untouched ng-pristine ng-invalid" v-model="name"
                   :placeholder="this.$i18n.t('signup.name')">
          </div>
          <div class="form-group">
            <input type="text" class="ng-untouched ng-pristine ng-invalid" v-model="mobile"
                   :placeholder="this.$i18n.t('signup.mobile')">
          </div>
          <div class="form-group">
            <input type="password" class="ng-untouched ng-pristine ng-invalid" v-model="password"
                   :placeholder="this.$i18n.t('signup.password')">
          </div>
          <div class="form-group">
            <input type="password" class="ng-untouched ng-pristine ng-invalid"
                   v-model="password_confirm" :placeholder="this.$i18n.t('signup.confirmPw')">
          </div>
          <div class="form-group">
            <input type="text" class="ng-untouched ng-pristine ng-valid" v-model="reffer"
                   :placeholder="this.$i18n.t('signup.inviteCode')">
          </div>
          <div class="form-group">
            <button class="btn btn-primary" @click="sign()">{{ $t('signup.join') }}</button>
            <!-- <router-link to="/sign/confm-code" class="btn btn-primary" >
                가입하기
            </router-link> -->
            <router-link to="/sign/login" class="btn btn-link"
                         style="color: #fff; font-size: 12px; margin-top: 16px;">{{
                $t('signup.already')
              }}
            </router-link>
          </div>
        </div>
      </div>
      <!-- <loading :active="loading" can-cancel="true" is-full-page="true"></loading> -->
    </b-overlay>
  </div>

</template>

<script>

const CryptoJS = require("crypto-js");

export default {
  data() {
    return {
      country: '',
      id: '',
      name: '',
      email: '',
      mobile: '',
      password: '',
      password_confirm: '',
      reffer: '',
      loading: false,
      show: false,
      country_list: []
    }
  },
  created() {
    this.$i18n.locale = this.$store.state.locale
    this.country_list = [
      {code: "", text: this.$i18n.t('signup.selectCountry')},
      {code: "US", text: this.$i18n.t('signup.countryUS')},
      {code: "KR", text: this.$i18n.t('signup.countryKO')}
    ];
  },
  mounted() {
    const code = this.$route.params.code;

    if (code == undefined) {
      this.reffer = "";
    } else {
      this.reffer = code;
    }

  },
  methods: {
    sign: function () {
      const id = this.id.trim();
      const name = this.name.trim();
      const reffer = this.reffer.trim();
      const email = this.email.trim();
      const mobile = this.mobile.trim();
      const country = this.country.trim();
      const password = this.password.trim();
      const password_confirm = this.password_confirm.trim();

      if (country === '') {
        this.$alert(`${this.$i18n.t('signup.selectCountry')}`);
        return false;
      }

      if (id === '') {
        this.$alert(`${this.$i18n.t('signup.alertId')}`);
        return false;
      }

      if (name === '') {
        this.$alert(`${this.$i18n.t('signup.alertName')}`);
        return false;
      }

      if (email === '') {
        this.$alert(`${this.$i18n.t('signup.alertEmail')}`);
        return false;
      }

      if (mobile === '') {
        this.$alert(`${this.$i18n.t('signup.alertMobile')}`);
        return false;
      }

      if (password === '') {
        this.$alert(`${this.$i18n.t('signup.alertPassword')}`);
        return false;
      }

      if (password !== password_confirm) {
        this.$alert(`${this.$i18n.t('signup.alertConfirmPw')}`);
        return false;
      }

      if (reffer === '') {
        this.$alert(`${this.$i18n.t('signup.alertRefer')}`);
        return false;
      }

      const body = {id, email, name, mobile, password, reffer, country};
      this.show = true;
      const req = CryptoJS.AES.encrypt(JSON.stringify(body),
          process.env.VUE_APP_SECRET_TOKEN).toString();
      this.loading = true;
      this.$axios.post('/member/sign', {req}).then(
          res => {
            if (res.status === 200) {
              this.show = false;
              if (res.data.code === 200) {
                this.$alert(`${this.$i18n.t('signup.signupComplete')}`).then(
                    () => {
                      this.$router.push({path: "/sign/login"})
                    }
                )
              } else if (res.data.code >= 130) {
                this.loading = false;
                this.$alert(res.data.msg);
              }
            }
          }
      )
    },
    onLocaleChange(event) {
      this.$i18n.locale = event.target.value;
      this.$store.dispatch('changeLocale', event.target.value);
      window.location.reload(true);
    }
  }
}
</script>
